<template>
    <div class="project-tasks-page">
        <full-loading ref="fullLoading"></full-loading>
        <div class="page-tools-content" style="margin: 0; padding: 0;">
            <div class="left">
                <el-tabs class="filter-tabs task-status-tabs" v-model="activeFilter" @tab-click="filterClickEvent">
                    <el-tab-pane label="全部" name="ALL"></el-tab-pane>
                    <el-tab-pane label="待接受" name="ASSIGNED"></el-tab-pane>
                    <el-tab-pane label="进行中" name="IN_PROGRESS"></el-tab-pane>
                    <!-- <el-tab-pane label="待提交" name="COMPLETED"></el-tab-pane> -->
                    <el-tab-pane label="已提交" name="FINISHED"></el-tab-pane>
                </el-tabs>
                
            </div>
            <div class="right" style="padding: 12px 0">
                
                <div style="width:220px;" class="margin-l-10">
                    <el-input placeholder="输入任务名称" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <div class="language-group margin-l-10">
                    <div style="width:125px;">
                        <el-select size="small" placeholder="源语言" clearable v-model="searchForm.source" @change="selectSearchEvent">
                            <template v-for="(item,key) in LANGUAGE_DICT">
                                <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.target === item.key"></el-option>
                            </template>
                        </el-select>
                    </div>
                    <div class="margin-l-5 fonts-14">
                        <svg-icon name="ib-arrow-right" style="color:#00000033;"></svg-icon>
                    </div>
                    <div class="margin-l-5" style="width:125px;">
                        <el-select size="small" placeholder="目标语言" clearable v-model="searchForm.target" @change="selectSearchEvent">
                            <template v-for="(item,key) in LANGUAGE_DICT">
                                <el-option :key="key" :value="key" :label="item.name" :disabled="searchForm.source === item.key"></el-option>
                            </template>
                        </el-select>
                    </div>
                </div>
                <template v-if="view === 'list' && activeFilter === 'ASSIGNED'">
                    <el-tooltip content="批量接受" placement="top">
                        <el-button size="small" plain round class="margin-l-10" style="padding: 6.4px; font-size: 18px;" @click="batchAcceptBtnEvent">
                            <svg-icon name="ib-check-circle"></svg-icon>
                        </el-button>
                    </el-tooltip>
                    <el-tooltip content="批量拒绝" placement="top">
                        <el-button size="small" plain round style="padding: 6.4px; font-size: 18px;" @click="batchRefuseBtnEvent">
                            <svg-icon name="ib-error-o"></svg-icon>
                        </el-button>
                    </el-tooltip>
                </template>
                <el-tooltip content="刷新" placement="top">
                    <el-button size="small" plain round icon="el-icon-refresh-left" class="margin-l-10" style="padding: 6.4px; font-size: 18px;" :loading="refreshLoading" @click="initPage(false)"></el-button>
                </el-tooltip>
                <div class="margin-l-10" v-if="userInfo.organizationType === 'ENTERPRISE'">
                    <el-popover placement="bottom" trigger="click">
                        <div class="more-filter-content">
                           <el-select v-model="searchForm.taskType" size="small" placeholder="任务类型" @change="selectSearchEvent" clearable style="width: 150px;">
                                <template v-for="item in taskTypeOptions">
                                    <el-option :key="item.code" :value="item.code" :label="item.desc"></el-option>
                                </template>
                            </el-select>
                            <el-select v-model="searchForm.order" size="small" placeholder="排序" @change="selectSearchEvent" clearable class="margin-t-10" style="width: 150px;">
                                <template v-for="item in orderOptions">
                                    <el-option :key="item.value" :value="item.value" :label="item.text"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <el-badge slot="reference" is-dot class="more-filter-badge" :hidden="!(searchForm.taskType || searchForm.order)">
                            <el-button plain round size="small" style="padding: 6.4px; font-size: 18px;">
                                <svg-icon name="ib-funnel"></svg-icon>
                            </el-button>
                        </el-badge>
                    </el-popover>
                </div>
                <div class="margin-l-10" v-permission="`project:detailRouter:delete`">
                    <el-tooltip :content="`切换${view==='card'?'列表':'卡片'}视图`" placement="top">
                        <el-button plain round size="small" @click="changeViewEvent" style="padding: 6.4px; font-size: 18px;">
                            <svg-icon name="ib-list" v-if="view === 'card'"></svg-icon>
                            <svg-icon name="ib-card" v-if="view === 'list'"></svg-icon>
                        </el-button>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <template v-if="view === 'card'">
            <div class="task-card-list" v-if="list.length">
                <template v-for="(item, index) in list">
                    <div class="item" :key="index" @click="toWorkbench(item)">
                        <div class="task-type-label" :class="{'rejected': item.returned}">{{item.taskTypeDesc}}{{item.returned ? '-退回' : ''}}</div>
                        <div class="flex flex-justify-center">
                            <div class="icon-content">
                                <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.'+(item.taskFileType || 'doc')].icon}`"></svg-icon>
                            </div>
                        </div>
                        <el-popover placement="top-start" trigger="hover">
                            <div class="fonts-12 line-height-1.5">
                                文件名：{{item.taskName}}<br>启动：{{item.startTime}}
                            </div>
                            <div slot="reference" class="fonts-15 margin-t-10 text-center text-omission">{{item.taskName}}</div>
                        </el-popover>
                        <div class="flex flex-justify-center fonts-12 margin-t-8">
                            <el-tooltip placement="top">
                                <span slot="content">
                                    {{LANGUAGE_DICT[item.sourceLang].name}}<svg-icon name="ib-arrow-right"></svg-icon>{{LANGUAGE_DICT[item.targetLang].name}}
                                </span>
                                <span>
                                    {{LANGUAGE_DICT[item.sourceLang].shortName}}<svg-icon name="ib-arrow-right"></svg-icon>{{LANGUAGE_DICT[item.targetLang].shortName}}
                                </span>
                            </el-tooltip>
                            <el-divider direction="vertical" class="margin-x-8"></el-divider>
                            <span>{{item.sourceWords}} 字</span>
                        </div>
                        <div class="flex flex-justify-center margin-t-8 fonts-12">
                            <span  :class="item.status">{{item.statusDesc}}</span>
                        </div>
                        <div class="flex flex-justify-center margin-t-10 fonts-12 color-808080">截止：<span :class="{'color-error': item.overdue}">{{item.deadlineTime || '—'}}</span></div>
                        <div class="lanx-btn-group margin-t-10" v-if="item.status === 'ASSIGNED'">
                            <el-button type="text" size="small" @click.stop="refuseBtnEvent([item])" v-permission="`tasks:refuse`" style="color: #808080;">拒绝</el-button>
                            <el-button type="text" size="small" @click.stop="acceptBtnEvent([item])" v-permission="`tasks:toAccept`">接受</el-button>
                        </div>
                        <template v-else>
                            <div class="margin-t-10 relative" v-if="item.status !== 'REJECTED'">
                                <el-progress :show-text="false" :stroke-width="32" :percentage="item.progress * 1" color="#D3F4EC" text-color="#1AC49C"></el-progress>
                                <div class="progress-text"><span class="color-1AC49C text-weight-600 margin-l-5">{{ item.progress }}%</span></div>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
        <div class="margin-t-15" v-else-if="view === 'list'">
            <task-table-list ref="taskTableList" :list="list" @action="executeAction"></task-table-list>
        </div>
        <div class="none-list-block" v-else>
            <div class="none-files-content">
                <svg-icon name="ib-empty"></svg-icon>
                <div class="info">没有任务～</div>
            </div>
        </div>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize" >
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <el-dialog title="接受任务" :visible.sync="showAcceptDialog" destroy-on-close width="612px" top="20px" @closed="selectedItem = []; $refs.taskTableList.resetTableSelected()">
            <div class="fonts-15 text-center line-height-1.5">确定接受以下任务吗？</div>
            <template v-for="(task, index) in selectedItem">
                <div class="text-center text-weight-600 line-height-1.5" :key="index">{{task.taskName}} {{task.taskTypeDesc}}</div>
            </template>
            <div class="text-center" slot="footer">
                <el-button plain round size="small" @click="showAcceptDialog = false">取消</el-button>
                <el-button type="primary" round size="small" :loading="acceptDialogLoading" @click="submitAcceptEvent">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="拒绝任务" :visible.sync="showRefuseDialog" destroy-on-close width="612px" top="20px" @closed="onRefuseDialogClosed">
            <div class="fonts-15 line-height-1.5" v-if="selectedItem">确定拒绝以下任务吗？</div>
            <template v-for="(task, index) in selectedItem">
                <div class="text-weight-600 line-height-1.5" :key="index">{{task.taskName}} {{task.taskTypeDesc}}</div>
            </template>
            <div class="margin-t-15">
                <el-input v-model="refuseReason" type="textarea" rows="3" placeholder="请填写拒绝理由（必填）"></el-input>
            </div>
            <div class="text-center" slot="footer">
                <el-button plain round size="small" @click="showRefuseDialog = false">取消</el-button>
                <el-button type="primary" round size="small" :loading="refuseDialogLoading" @click="submitRefuseEvent">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { projectApi } from '@/utils/api';
import FullLoading from '@/components/FullLoading';
import TaskTableList from './components/TaskTableList';
import config from '@/utils/config';
export default {
    components: {
        FullLoading, TaskTableList, 
    },
    data () {
        return {
            config,
            view: 'card', // list
            refreshLoading: false,
            searchForm: {
                kw: '',
                source: '',
                target: '',
                owner: '',
                taskType: '',
                order: '',
            },
            list: [],
            total: 0,
            pageNumber: 1,
            pageSize: 16,
            activeFilter: 'ASSIGNED',
            searchTimer: null,
            ownerOptions: [],
            taskTypeOptions: [],
            orderOptions: [
                {value: 'FILENAME_ASC', text: '按文件名正序'},
                {value: 'FILENAME_DESC', text: '按文件名倒序'},
                {value: 'START_TIME_ASC', text: '按启动时间正序'},
                {value: 'START_TIME_DESC', text: '按启动时间倒序'},
                {value: 'DEADLINE_TIME_ASC', text: '按截止时间正序'},
                {value: 'DEADLINE_TIME_DESC', text: '按截止时间倒序'},
            ],
            showAcceptDialog: false,
            acceptDialogLoading: false,

            showRefuseDialog: false,
            refuseDialogLoading: false,
            refuseReason: '',

            selectedItem: [],
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods: {
        initPage(showFullLoading){
            if(showFullLoading){
                this.$refs.fullLoading.setShow();
            }
            let url = `${projectApi.queryMyTaskList}?pageNo=${this.pageNumber}&pageSize=${this.pageSize}`;
            if(this.searchForm.kw) {
                url = `${url}&taskNameKeyword=${encodeURI(this.searchForm.kw)}`;
            }
            if(this.searchForm.source) {
                url = `${url}&source=${this.searchForm.source}`;
            }
            if(this.searchForm.target) {
                url = `${url}&target=${this.searchForm.target}`;
            }
            if(this.searchForm.taskType){
                url = `${url}&taskType=${this.searchForm.taskType}`;
            }
            if(this.activeFilter !== 'ALL'){
                url = `${url}&taskStatus=${this.activeFilter}`;
            }
            if(this.searchForm.order){
                url = `${url}&order=${this.searchForm.order}`;
            }
            this.refreshLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.list = res.data.records;
                    this.total = res.data.total;
                }
            }).finally(() => {
                this.refreshLoading = false;
                this.$refs.fullLoading.setHide();
            })
        },
        initOwnerOptions(){
            let url = `${projectApi.queryAssignedList}?teamId=${this.userInfo.initTeam.teamId}&keyword=`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.ownerOptions = res.data;
                }
            })
        },
        initTaskTypeOptions () {
            let url = projectApi.queryTaskTypeList;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.taskTypeOptions = res.data.taskType;
                }
            })
        },
        handleCurrentChange(val) {
            this.pageNumber = val;
            this.$nextTick(()=> {
                this.initPage(false);
            })
        },
        inputSearchEvent(val){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                self.initPage(false);
            }, 500)
        },
        selectSearchEvent(){
            this.$nextTick(()=> {
                this.initPage(false);
            })
        },
        filterClickEvent(tab, event){
            this.$nextTick(()=> {
                this.pageNumber = 1;
                this.initPage(false);
            })
        },
        toWorkbench(item){
            if(['ASSIGNED', 'REJECTED'].indexOf(item.status) > -1){
                return;
            }
            this.$router.push({path:'/workbench/editer', query: {id: item.documentId, taskId: item.taskId}});
        },
        batchRefuseBtnEvent() {
            if(this.selectedItem.length === 0) {
                this.$message.error('请先选择任务');
                return;
            }
            this.showRefuseDialog = true;
        },
        refuseBtnEvent(item) {
            this.selectedItem = item;
            this.showRefuseDialog = true;
        },
        submitRefuseEvent() {
            let ids = [];
            this.selectedItem.map(item => {
                ids.push(item.taskId);
            });
            let url = projectApi.acceptOrRefuseTask;
            let postData = {
                taskIdList: ids,
                reason: this.refuseReason,
                accepted: false,
            };
            this.refuseDialogLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$message.success('已拒绝任务');
                    this.showRefuseDialog = false;
                    this.initPage(false);
                }
            }).finally(() => {
                this.refuseDialogLoading = false;
            });
        },
        onRefuseDialogClosed() {
            this.selectedItem = [];
            this.refuseReason = '';
            this.$refs.taskTableList.resetTableSelected();
        },
        batchAcceptBtnEvent() {
            if(this.selectedItem.length === 0) {
                this.$message.error('请先选择任务');
                return;
            }
            this.showAcceptDialog = true;
        },
        acceptBtnEvent(item) {
            this.selectedItem = item;
            this.showAcceptDialog = true;
        },
        submitAcceptEvent() {
            let ids = [];
            this.selectedItem.map(item => {
                ids.push(item.taskId);
            });
            let url = projectApi.acceptOrRefuseTask;
            let postData = {
                taskIdList: ids,
                accepted: true,
            };
            this.acceptDialogLoading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200){
                    this.$message.success('已接受任务');
                    this.showAcceptDialog = false;
                    this.initPage(false);
                }
            }).finally(() => {
                this.acceptDialogLoading = false;
            });
        },
        changeViewEvent() {
            if(this.view === 'card') {
                this.view = 'list';
            }else if(this.view === 'list') {
                this.view = 'card';
            }
            this.$ls.set('TaskView', this.view);
        },
        executeAction(key, params) {
            switch (key) {
                case 'updateSelected':
                    this.selectedItem = params;
                    break;
                case 'refuse':
                    this.selectedItem = params;
                    this.showRefuseDialog = true;
                    break;
                case 'accept':
                    this.selectedItem = params;
                    this.showAcceptDialog = true;
                    break;
            }
        },
    },
    created() {
        let _view = this.$ls.get('TaskView');
        if(_view){
            this.view = _view;
        }else {
            if(this.userInfo.internal) {
                this.view = 'list';
            }
        }
    },
    async mounted() {
        this.initPage(true);
        this.initTaskTypeOptions();
        
    }
}
</script>
<style lang="scss" scoped>
.project-tasks-page{
    .task-status-tabs {
        ::v-deep .el-tabs__nav-wrap {
            &::after {
                display: none;
            }
        }
        ::v-deep .el-tabs__item {
            height: 57.1px;
            line-height: 57.1px;
        }
    }
    .task-card-list{
        margin: 30px 90px;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill,minmax(190px,1fr));
        .item {
            // min-height: 228px;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 30px 15px 20px 15px;
            cursor: pointer;
            position: relative;
            .task-type-label {
                position: absolute;
                left: 0;
                top: 0;
                height: 24px;
                display: flex;
                padding: 0 10px;
                align-items: center;
                font-size: 12px;
                color: #1AC49C;
                background-color: #DDF3EB;
                border-radius: 10px 0 10px 0;
                &.rejected {
                    color: #F56C6C;
                    background-color: #FDE2E2;
                }
            }
            .icon-content{
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: #F8F8F8;
                font-size: 27px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .go-btn{
                width: 50px;
                height: 24px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                background-color: #f5f5f5;
            }
            .TO_START {
                color: #FE8E0B;
            }
            .IN_PROGRESS {
                color: #2979FF;
            }
            .COMPLETED,
            .FINISHED {
                color: #00B670;
            }
            .ASSIGNED {
                color: #FF9900;
            }
            .REJECTED {
                color: #999999;
            }
            .progress-text {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            ::v-deep .el-progress-bar__outer{
                background-color: #F8F8F8;
            }
        }
    }
}
.more-filter-content {
    display: flex;
    flex-direction: column;
    ::v-deep .el-select {
        .el-input__inner {
            border-radius: 20px;
        }
    }
}
.more-filter-badge {
    ::v-deep .el-badge__content.is-dot {
        top: 5px;
        right: 8px;
    }
}
</style>
